import { useEffect } from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import { getConfig, getItemsBySKU, useConfig } from '~/store/Query/api';

export const planesState = hookstate([]);

const planesNameState = hookstate({});

export const usePlanesState = (enabled) => {
  const planes = (planesState);
  useEffect(() => {
    async function init() {
      if (enabled && planes.value?.length) {
        const sku = planes.value.filter(plan => !planesNameState.value[plan]);
        if (sku.length) {
          const res = await getItemsBySKU({ sku });
          if (res.ok) {
            planesNameState.merge(res.body.reduce((obj, prod) => {
              obj[prod.items[0].part_number] = prod.items[0].locales[process.env.NEXT_PUBLIC_LOCALE].name;
              return obj;
            }, {}));
          }
        }
      }
    }

    init();
  }, [planes.value]);
  return useHookstate(planesNameState);
};

export const CONEQUIPO = 'CONEQUIPO';
export const SINEQUIPO = 'SINEQUIPO';
export const AMBOS = 'AMBOS';

export const planesEquipo = hookstate({
  config: {
    product_sku_sin_equipo: 'K01_0001_001',
    mostrar_plazos_sin_equipo: '1',
    titulo_categoria_con_equipo: 'Planes todo incluido',
    tipo_postpago_predefinido: CONEQUIPO,
    terminos_condiciones_sin_equipo: '',
    titulo_categoria_sin_equipo: 'Planes sin equipo',
  },
  tipo: null,
  show: false,
});

const setPopagoState = (data) => {
  planesEquipo.config.merge(data);
  planesEquipo.config.product_sku_sin_equipo.set(planesEquipo.config.product_sku_sin_equipo.value.replace(/[.]/g, '_'));
  if (!planesEquipo.tipo.value) {
    planesEquipo.tipo.set(planesEquipo.config.tipo_postpago_predefinido.value);
  }
};

export const usePospagoState = (enabled = true) => {
  const config = useConfig('configuracion_tienda', enabled);
  useEffect(() => {
    if (config.data?.ok) {
      const info = config.data.body.data;
      setPopagoState(info.texto);
    }
  }, [config.data]);
  return useHookstate(planesEquipo);
};

export const getPospagoState = async () => {
  const config = await getConfig('configuracion_tienda');
  if (config.ok) {
    setPopagoState(config.body.data.texto);
    return planesEquipo;
  }
  return null;
};

export const setPlanEquipo = tipo => () => planesEquipo.tipo.set(tipo);
